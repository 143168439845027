<template>
  <div v-if="isLoading" class="loading-wrapper">
    <SpinnerIcon />
  </div>
  <div v-else-if="dataRecapProject.length < 1" class="columns is-centered">
    <div class="column has-text-centered">
      <ProyekEmptyIcon />
      <p class="averta-bold mt-2">Rekap Hasil Proyek tidak ditemukan</p>
    </div>
  </div>
  <div v-else>
    <div class="body-wrapper mb-4" v-for="(itemWorker, indexWorker) in dataRecapProject" :key="`${indexWorker}_worker`">
      <div class="header-detail p-5 flex-sm-column align-items-sm-start">
        <div class="is-flex mb-sm-1">
          <div class="tukang-img mr-3">
            <img v-if="itemWorker.worker_image && itemWorker.worker_image !== ''" :src="itemWorker.worker_image" alt="worker_img" class="is-clickable" @click="openModalPreviewImage({ singleImage: itemWorker.worker_image })" />
            <img v-else :src="require('@/assets/img/default-picture.jpg')" alt="default_pic" />
          </div>
          <div>
            <p class="averta-bold">{{ itemWorker.worker_name }}</p>
            <p class="label-cs">ID{{ itemWorker.worker_id }}</p>
          </div>
        </div>
        <div class="is-flex">
          <div class="jenis-tukang-img mr-3">
            <img :src="itemWorker.skill_image || require('@/assets/img/default-picture.jpg')" alt="default_pic" />
          </div>
          <div>
            <p class="label-cs">Jenis Tukang</p>
            <p class="averta-bold">{{ itemWorker.skill_name }}</p>
          </div>
        </div>
      </div>
      <div class="p-4">
        <p class="averta-bold mb-4">Rekap Hasil Proyek</p>
        <div v-for="(itemWorking, indexWorking) in itemWorker.item_working" :key="`${indexWorking}_note_item_working`" class="detail-wrapper mb-4">
          <div class="foto-wrapper">
            <div class="align-self-sm-start">
              <p>Detail Pekerjaan</p>
              <p class="averta-bold mb-2">{{ itemWorking.note_item_working }}</p>
              <div v-if="itemWorking.pictures && itemWorking.pictures.length > 0" class="is-flex">
                <div v-for="(itemPicture, indexPicture) in itemWorking.pictures" :key="indexPicture" @click="openModalPreviewImage(itemWorking.pictures)" class="wrapper-preview-image cursor-pointer is-relative mr-3">
                  <img :src="itemPicture" alt="" />
                </div>
              </div>
            </div>
            <div class="is-flex w-sm-100 mt-sm-2 overflow-sm-auto">
              <div class="before-after-wrapper mr-6 mr-sm-0 pr-sm-2">
                <div class="is-flex is-justify-content-space-between mb-1">
                  <p>Sebelum</p>
                  <p v-if="itemWorking.photo_befores.length > 0" class="text-red ml-3 is-clickable" @click="handleShowSidebar({worker: itemWorker, dataNoteItemWorking: itemWorking})">Lihat</p>
                </div>
                <div v-if="itemWorking.photo_befores.length > 0" class="is-flex">
                  <div v-for="(itemBefore, indexBefore) in itemWorking.photo_befores.slice(0, 3)" :key="`${indexBefore}_data_before`" class="before-after-img mr-2">
                    <img class="cursor-pointer" :src="itemBefore" @click="openModalPreviewImage(itemWorking.photo_befores)" alt="img_before" />
                  </div>
                </div>
                <img v-else :src="require('@/assets/img/empty-img.png')" alt="empty" />
              </div>
              <div class="before-after-wrapper">
                <div class="is-flex is-justify-content-space-between mb-1">
                  <p>Sesudah</p>
                  <p v-if="itemWorking.photo_afters.length > 0" class="text-red ml-3 is-clickable" @click="handleShowSidebar({worker: itemWorker, dataNoteItemWorking: itemWorking})">Lihat</p>
                </div>
                <div v-if="itemWorking.photo_afters.length > 0" class="is-flex">
                  <div v-for="(itemAfter, indexAfter) in itemWorking.photo_afters.slice(0, 3)" :key="`${indexAfter}_data_after`" class="before-after-img mr-2">
                    <img class="cursor-pointer" :src="itemAfter" @click="openModalPreviewImage(itemWorking.photo_afters)" alt="img_after" />
                  </div>
                </div>
                <img v-else :src="require('@/assets/img/empty-img.png')" alt="empty" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ModalPreviewImage
      title=""
      :isActive="modalPreviewImageActive"
      :dataImage="imagePreviewCarousel"
      @closeModal="closeModalPreviewImage()" />

    <SidebarBeforeAfter
      :showSidebar="sidebarBeforeAfter"
      :data="selectedDataWorker"
      type="detail"
      @closeSidebar="handleShowSidebar"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ModalPreviewImage from '@/components/modal/ModalPreviewImage'
import { ProyekEmptyIcon, SpinnerIcon } from '@/components/icons'
import { alertErrorMessage } from '@/helpers'
import SidebarBeforeAfter from '@/components/SidebarBeforeAfter.vue'

export default {
  name: 'TabRekapHasilProyek',
  components: {
    ProyekEmptyIcon,
    SpinnerIcon,
    ModalPreviewImage,
    SidebarBeforeAfter
  },
  data () {
    return {
      isLoading: false,
      imagePreviewCarousel: [],
      modalPreviewImageActive: false,
      dataRecapProject: [],
      sidebarBeforeAfter: false,
      selectedDataWorker: null
    }
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl'
    })
  },
  methods: {
    closeModalPreviewImage () {
      this.imagePreviewCarousel = null
      this.modalPreviewImageActive = false
    },
    openModalPreviewImage (data) {
      this.imagePreviewCarousel = data
      this.modalPreviewImageActive = true
    },
    getRecapProject () {
      this.isLoading = true
      const payload = {
        url: this.url,
        proyek_id: this.$route.params.proyekId,
        path_name: 'recap-attendance'
      }
      this.$store
        .dispatch('projectV2/getDetailProject', payload)
        .then((response) => {
          this.dataRecapProject = response.data.data
          this.isLoading = false
        })
        .catch((error) => {
          this.isLoading = false
          alertErrorMessage(error)
        })
    },
    // Sidebar before-after
    handleShowSidebar (props = {}) {
      const { worker, dataNoteItemWorking } = props
      this.sidebarBeforeAfter = !this.sidebarBeforeAfter
      if (worker) {
        this.selectedDataWorker = {
          ...worker,
          note_item: dataNoteItemWorking.note_item_working,
          before: dataNoteItemWorking.photo_befores,
          after: dataNoteItemWorking.photo_afters
        }
      } else {
        this.selectedDataWorker = null
      }
    }
  },
  created () {
    this.getRecapProject()
  }
}
</script>

<style scoped>
.body-wrapper {
  background: #ffffff;
  border: 1px solid #d8d8d8;
  border-radius: 12px;
  overflow: hidden;
}
.header-detail {
  background: linear-gradient(90deg, #ffdddd 0%, #ffefdb 99.97%);
  border-radius: 12px 12px 0px 0px;
  justify-content: space-between;
  align-items: center;
  display: flex;
}
.label-cs {
  font-size: 14px;
  color: #868686;
}
.flex-1 {
  flex: 1;
}
.jenis-tukang-img {
  width: 40px !important;
  height: 40px !important;
}
.jenis-tukang-img,
.before-after-img,
.tukang-img {
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.before-after-img,
.tukang-img {
  width: 35px !important;
  height: 35px !important;
  border-radius: 35px;
}
.before-after-img {
  border-radius: 5px !important;
}
.jenis-tukang-img img,
.before-after-img img {
  width: 100%;
  height: 100%;
}
.foto-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.detail-wrapper {
  background: #fafafa;
  border-radius: 20px;
  padding: 20px;
}
.text-red {
  background: linear-gradient(116.12deg, #d9272d 16.15%, #eb4600 83.24%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}
.before-after-wrapper {
  min-width: 10em;
}
.modal-wrapper {
  overflow-x: hidden;
}
.header-modal {
  background: #911a1e;
  padding: 24px;
  color: white;
  border-radius: 12px 12px 0px 0px;
}
.body-modal {
  background: white;
  border-radius: 0px 0px 12px 12px;
  height: 70vh;
  overflow-y: auto;
  overflow-x: hidden;
}
.before-after {
  width: 380px;
  height: 380px;
  border-radius: 24px;
}
@media only screen and (max-width: 600px) {
  .foto-wrapper {
    flex-direction: column;
    width: 100%;
  }

  .flex-sm-column {
    flex-direction: column;
  }

  .align-self-sm-start {
    align-self: flex-start;
  }

  .w-sm-100 {
    width: 100%;
  }

  .mr-sm-0 {
    margin-right: 0 !important;
  }

  .mt-sm-2 {
    margin-top: 1rem;
  }

  .header-detail {
    padding: 1rem !important;
  }
}
</style>
