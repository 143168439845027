// eslint-disable-next-line no-unused-vars
import { io } from 'socket.io-client'

class SocketioService {
  socket;
  URL = process.env.VUE_APP_SHELL_URL_SOCKET

  setupSocketConnection () {
    this.socket = io(this.URL, {
      withCredentials: true
    })
  }

  getPreviewNotif (cb) {
    if (!this.socket) return (true)

    const rs = JSON.parse(localStorage.getItem('webapp_shell_kanggo'))
    const socketEmit = `notifpreview-${rs.user.id}-${rs.user.roles[0].name}`

    this.socket.on(socketEmit, msg => {
      return cb(msg)
    })
  }

  getNotif (cb) {
    if (!this.socket) return (true)

    const rs = JSON.parse(localStorage.getItem('webapp_shell_kanggo'))
    const socketEmit = `notif-${rs.user.id}-${rs.user.roles[0].name}`

    this.socket.on(socketEmit, msg => {
      return cb(msg)
    })
  }

  disconnect () {
    if (this.socket) {
      this.socket.disconnect()
    }
  }
}

export default new SocketioService()
