<template>
  <ModalTemplate width="550px" :showModal="isModalActive">
    <template v-slot:headerModal>
      <div class="is-flex is-justify-content-space-between is-align-items-center">
        <p class="averta-bold label-18">{{modalType === 'edit' ? `Edit ${initialForm.name}` : 'Tambah User'}}</p>
        <div class="is-clickable" @click="closeModal(false)">
          <b-icon icon="close"></b-icon>
        </div>
      </div>
    </template>
    <template v-slot:bodyModal>
      <b-loading :is-full-page="true" v-model="isLoadingSubmit" :can-cancel="false">
        <SpinnerIcon />
      </b-loading>

      <div v-if="isLoadingDetail" class="body-modal-user">
        <div class="form-body">
          <ModalUserSkeleton />
        </div>
      </div>

      <div v-else class="body-modal-user is-relative">
        <div class="form-body">
          <div class="flex-center is-flex-direction-column">
            <label for="photo-profile">
              <input
                type="file"
                id="photo-profile"
                @change="profileSelected"
                hidden
                accept="image/png, image/jpeg"
              />
              <div v-if="previewImage" class="is-clickable image-profile flex-center mb-3">
                <img :src="previewImage" alt="" />
              </div>
              <div class="wrapper-camera mb-3 flex-center is-clickable" v-else>
                <CameraIcon />
              </div>
              <p
                class="is-clickable color-red has-text-centered"
                v-if="(!profilePicture || profilePicture === PREFIX_EDIT) && !previewImage"
              >
                Upload Foto
              </p>
            </label>
            <p
              class="is-clickable color-red"
              v-if="(profilePicture && profilePicture !== PREFIX_EDIT) || previewImage"
              @click="handleClearPhoto"
            >
              Hapus Foto
            </p>
          </div>

          <div class="mt-4">
            <div class="flex-center is-justify-content-space-between mb-4">
              <p class="flex-1">Nama Lengkap <span class="has-text-danger">*</span></p>
              <b-input class="input-search-2 input-none c-w-55" placeholder="Masukkan nama lengkap" v-model="form.name"></b-input>
            </div>
            <div class="flex-center is-justify-content-space-between mb-4">
              <p class="flex-1">Email <span class="has-text-danger">*</span></p>
              <b-input class="input-search-2 input-none c-w-55" v-model="form.email" placeholder="Masukkan email"></b-input>
            </div>
            <div class="flex-center is-justify-content-space-between mb-4">
              <p class="flex-1">No. Handphone / WhatsApp <span class="has-text-danger">*</span></p>
              <b-input
                class="input-search-2 input-none c-w-55"
                v-model="form.phone"
                placeholder="Masukkan no. handphone / whatsApp"
                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                type="number"
                maxlength="14"
              ></b-input>
            </div>
            <div class="flex-center is-justify-content-space-between is-relative">
              <p class="flex-1">Role <span class="has-text-danger">*</span></p>
              <div
                class="input-search-2 input-none c-w-55 p-2 is-clickable ellipsis-content flex-grow-0"
                style="height: 41px;"
                @click="handleSelectRole"
                ref="refCheckboxRole"
              >
                <p style="width: 95%;" :class="{'color-grey-1 label-14': form.role.length < 1}">{{displaySelectedRole()}}</p>
                <p
                  class="is-inline-block arrow-transition"
                  :class="{'reverse': showListRole}"
                >
                  &#9660;
                </p>
              </div>

              <!-- LIST CHECKBOX ROLE -->
              <div
                v-if="showListRole"
                class="role-checkbox-wrapper"
                v-closable2="{
                  exclude: ['refCheckboxRole'],
                  handler: 'closeListRole'
                }"
              >
                <div class="list-checkbox flex-center-vertical">
                  <p>Pilih Role</p>
                </div>
                <div class="list-checkbox-wrapper">
                  <div class="list-checkbox flex-center-vertical is-justify-content-space-between" v-for="(item, index) in formCheckbox" :key="index">
                    <p :class="{'averta-bold': item.value}">{{item.name}}</p>
                    <b-checkbox v-model="item.value">
                    </b-checkbox>
                  </div>
                </div>
                <div class="mt-3 is-flex is-justify-content-flex-end">
                  <ButtonApp @click="handleSelectCheckbox">
                    <p class="averta-bold py-1 px-4">Pilih</p>
                  </ButtonApp>
                </div>
              </div>
            </div>

          </div>
        </div>
        <div
          class="footer-user px-4 py-3 is-flex is-justify-content-flex-end is-align-items-center"
        >
          <ButtonApp :isSecondary="true" @click="closeModal(false)">
            <p class="averta-bold py-2 px-4">Batalkan</p>
          </ButtonApp>
          <ButtonApp class="ml-4" :isDisabled="disabledSubmit || isLoadingSubmit" @click="handleAddEditUser">
            <div class="flex-center-vertical py-1 px-4">
              <CheckIcon />
              <p class="averta-bold ml-1">Simpan</p>
            </div>
          </ButtonApp>
        </div>
      </div>
    </template>
  </ModalTemplate>
</template>

<script>
import { mapGetters } from 'vuex'
import { alertErrorMessage, isEmptyOrInvalid, formatRoleTitle, isObjectsSimilar } from '@/helpers'
import { CameraIcon, CheckIcon, SpinnerIcon } from '@/components/icons'
import ModalTemplate from '@/components/modal/ModalTemplate.vue'
import ButtonApp from '@/components/button/ButtonApp.vue'
import ModalUserSkeleton from '@/components/skeleton/ModalUserSkeleton.vue'

export default {
  name: 'ModalUser',
  components: {
    CheckIcon,
    CameraIcon,
    SpinnerIcon,
    ButtonApp,
    ModalTemplate,
    ModalUserSkeleton
  },
  props: {
    showModal: {
      type: Boolean,
      default: false
    },
    userId: {
      type: [String, Number]
    },
    modalType: {
      type: String,
      required: true
    },
    roles: {
      type: Array,
      default: () => []
    },
    dataUser: {
      type: Object,
      default: null
    }
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl'
    })
  },
  data () {
    return {
      isLoadingDetail: false,
      isLoadingSubmit: false,
      isModalActive: false,
      profilePicture: null,
      previewImage: null,
      initPreviewImage: null,
      initialForm: null,
      form: {
        is_active: 1, // NOT READY, DEFAULT 1
        name: '',
        email: '',
        phone: '',
        role: []
      },
      showListRole: false,
      disabledSubmit: true,

      formCheckbox: this.roles,
      PREFIX_EDIT: 'initial_edit'
    }
  },
  methods: {
    closeModal (refreshData) {
      this.form.role = []
      this.formCheckbox = []
      this.$emit('closeModal', { refreshData })
    },
    closeListRole () {
      this.showListRole = false
    },
    displaySelectedRole () {
      if (this.form.role.length < 1) return 'Pilih Role'
      return formatRoleTitle(this.form.role.map(item => item.name).join('; '))
    },
    profileSelected (e) {
      e.preventDefault()
      const file = e.target.files[0]

      if (file) {
        this.profilePicture = file
        this.previewImage = URL.createObjectURL(e.target.files[0])
      }
    },
    handleSelectRole () {
      this.showListRole = !this.showListRole
    },
    handleSelectCheckbox () {
      this.showListRole = false
      this.form.role = this.formCheckbox.filter(item => item.value)
    },
    handleAddEditUser () {
      if (this.isLoadingSubmit) return

      const payload = {
        url: this.url,
        body: this.form
      }

      const tempRole = JSON.parse(JSON.stringify(this.form.role))
      payload.body.role = tempRole.map(item => item.id)
      payload.body.typeAPI = this.modalType

      delete payload.body.photo
      const isEmptyForm = Object.values(payload.body).some((value) => isEmptyOrInvalid(value, payload.body))
      if (isEmptyForm) {
        return alertErrorMessage('Mohon lengkapi form')
      }

      if (this.profilePicture && typeof this.profilePicture !== 'string') {
        payload.body.photo = this.profilePicture
      }

      if (this.modalType === 'edit') {
        payload.body.userId = this.userId
      }

      this.isLoadingSubmit = true
      this.$store
        .dispatch('user/postPutAddDataUser', payload)
        .then(() => {
          const isEdit = this.modalType === 'edit'
          let message = ''
          if (isEdit) {
            message = 'Data Berhasil Diubah'
          } else {
            message = `${this.form.name} Berhasil Ditambahakan`
          }

          this.$buefy.toast.open({
            message: `<p class="averta-bold">${message}</p>`,
            type: 'is-success'
          })
          this.closeModal(true)
        })
        .catch(error => {
          this.isLoadingSubmit = false
          alertErrorMessage(error)

          // RESET CHECKBOX VALUE
          this.form.role = tempRole
        })
    },
    handleClearPhoto () {
      this.profilePicture = null
      this.previewImage = null
    },
    getDetailUser () {
      this.isLoadingDetail = true
      const payload = {
        url: this.url,
        userId: this.userId
      }

      this.$store
        .dispatch('user/getDetailUser', payload)
        .then(response => {
          const res = response.data.data
          // eslint-disable-next-line camelcase
          const { name, email, phone, is_active, roles, profile_picture } = res
          const selectedRoles = roles.map(obj => ({
            ...obj,
            name: obj.name === 'Account Manager' ? 'Business Support' : obj.name
          }))

          this.formCheckbox = this.roles.map(obj => ({
            ...obj,
            value: selectedRoles.some(item => item.name === obj.name)
          }))

          const role = roles.map(obj => {
            return { ...obj, value: true }
          })
          this.form = {
            name,
            email,
            phone,
            is_active,
            role
          }
          this.initialForm = JSON.parse(JSON.stringify(this.form))

          // eslint-disable-next-line camelcase
          this.initPreviewImage = profile_picture
          // eslint-disable-next-line camelcase
          this.previewImage = profile_picture
          this.profilePicture = this.PREFIX_EDIT
          this.isLoadingDetail = false
        })
        .catch(error => {
          this.isLoadingDetail = false
          alertErrorMessage(error)
        })
    },
    checkIsEmpty () {
      const isEmptyForm = Object.values(this.form).some(value => value === '' || value.length < 1)
      const isSimilar = isObjectsSimilar(this.initialForm, this.form)
      const isSamePicture = this.initPreviewImage === this.previewImage

      if (!isEmptyForm && !isSimilar) {
        this.disabledSubmit = false
      } else if ((isEmptyForm || isSimilar) && isSamePicture) {
        this.disabledSubmit = true
      } else if (!isEmptyForm && !isSamePicture) {
        this.disabledSubmit = false
      } else if (isEmptyForm || isSimilar) {
        this.disabledSubmit = true
      }
    }
  },
  watch: {
    showModal (value) {
      this.isModalActive = value
      if (value) {
        this.form = {
          is_active: 1, // NOT READY, DEFAULT 1
          name: '',
          email: '',
          phone: '',
          role: []
        }
        this.initialForm = JSON.parse(JSON.stringify(this.form))
        if (this.modalType === 'edit') {
          this.getDetailUser()
        } else if (this.modalType === 'add') {
          this.previewImage = null
          this.profilePicture = null
          this.formCheckbox = this.roles
          this.form.role = []
        }
      }
    },
    roles (value) {
      this.formCheckbox = value
    },
    form: {
      handler () {
        this.checkIsEmpty()
      },
      deep: true
    },
    previewImage (value) {
      this.checkIsEmpty()
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/scss/_variables.scss';

.body-modal-user {
  background: white;
  border-radius: 0px 0px 12px 12px;
  height: 540px;
}

.form-body {
  padding: 20px 30px;
  height: 88%;
  overflow: auto;
}

.image-profile,
.wrapper-camera {
  height: 160px;
  width: 160px;
  border-radius: 100px;
  overflow: hidden;
}

.image-profile img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.wrapper-camera {
  border: 2px $color-red-1 dashed;
}

.footer-user {
  width: 100%;
  position: absolute;
  bottom: 0;
  box-shadow: 0px -3px 30px 0px #00000012;
  background: white;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}

.role-checkbox-wrapper {
  position: absolute;
  bottom: 41px;
  right: 0;
  padding: 20px;
  padding-bottom: 12px;
  width: 330px;
  background: white;
  border-radius: 20px;
  box-shadow: 6px 7px 50px 0px #00000014;
}

.list-checkbox-wrapper {
  max-height: 220px;
  overflow: auto;
}

.list-checkbox {
  height: 44px;
  border-bottom: 1px solid $color-grey-2;
}

.arrow-transition {
  transition: all ease-in-out 0.2s;
  position: absolute;
  right: 10px;
  bottom: 9px;
}

.reverse {
  transform: rotate(180deg);
}
</style>
